#container-trade-record {
    height: 100%;
    overflow: auto;
}

.td-purchase {
    color: red;
}

.td-sell {
    color: green;
}
