.constituents-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.constituent-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
    padding: 0 18px;
}

.constituent-header {
    flex-grow: 0;
    margin-top: 0;
    margin-bottom: 10px;
}

.conjunction-header {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.constituent-list {
    flex-grow: 1;
    overflow: auto;
}

.constituent-list-item-disabled {
    background-color: #f5f5f5;
    color: #b9b9b9;
}