.row-even {
    background-color: #0000000f;
}

.profit_percentage_positive {
    color: #ff6827;
}

.profit_percentage_negative {
    color: #3da742;
}

.percentage_today {
    font-weight: 700;
}
