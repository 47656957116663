.parent-large {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 3fr 3fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px 16px;
    grid-template-areas: 'main a b' 'main c d';
}

.parent-small {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.chart-card-large {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.chart-card-small {
    width: 100%;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.card-title-large {
    margin-left: 24px;
    margin-top: 12px;
    font-weight: 800;
    font-size: 36px;
}

.card-title-small {
    margin-left: 16px;
    margin-top: 8px;
    font-weight: 800;
    font-size: 20px;
}