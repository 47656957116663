#container-convertible-bond-watchdog {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0
}

#titlebar-convertible-bond-watchdog {
    padding: 0 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#title-convertible-bond-watchdog {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    flex-grow: 1;
}

#popover-add-convertible-bond-watchdog {
}

#ant-popover-inner-content {
    padding: 8px 12px;
}

#add-button-convertible-bond-watchdog {
}

#input-add-convertible-bond-watchdog {
    padding: 0;
}

#convertible-bond-watchdog-list {
    flex-grow: 1;
    overflow: auto;
}

#convertible-bond-watchdog-list::-webkit-scrollbar {
    display: none;
}

.ant-list-item {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.ant-list-item-action {
    margin-left: 0;
}

.li-arriving {
    background: #3b8a3630;
}