.level_very_high {
    background-color: #FACAC0
}

.level_high {
    background-color: #F0CF61
}

.level_normal {
    background-color: #A6CFE2
}

.level_low {
    background-color: #61D090
}

.level_very_low {
    background-color: #EAEAEA
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset;
}

td {
    font-size: 15px;
}
