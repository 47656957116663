@import '~antd/dist/antd.css';

html,
body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
}

#root {
    height: 100%;
}

.scaffold-layout {
    height: 100vh;
}

#sider::-webkit-scrollbar {
    display: none;
}

.div-sider-logo {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    margin: 0;
    font-family: Bebas, serif;
    text-align: center;
    font-size: 40px;
    line-height: 1.4;
    letter-spacing: 1px;
    display: block;
    padding: 0 2px 0 5px;
    z-index: 1;
    position: relative;
    box-sizing: border-box;
    background: linear-gradient(to top, #FFE600 35%, transparent 35%);
}

.sidebar-divider {
    display: block;
    width: 20%;
    height: 2px;
    background-color: #FFE600;
    margin: 20px auto;
}

.ant-menu-vertical {
    border-right: none;
}

.ant-menu-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFFFFF00;
    /*background-color: #FFE60033;*/
}

.ant-menu-item:active {
    background-color: #FFFFFF00;
}

.ant-menu-item a, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: rgba(0, 0, 0, 0.75) !important;
}

.ant-menu {
    /*font-size: 16px;*/
    letter-spacing: 4px;
}

.ant-menu-item a {
    font-weight: 400;
    /*font-weight: 300;*/
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    font-size: 15px;
    font-weight: 900;
}

.ant-menu-item-selected a {
    z-index: 1;
    position: relative;
}

.ant-menu-item-selected a:after {
    position: absolute;
    content: '';
    height: 40px;
    width: 150px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    /*border-color: #FFE600;*/
    /*border-style: solid;*/
    /*border-width: 1px;*/
    background: #FFE600;
    box-shadow: 0 2px 20px 5px #FFE60066 !important;
    z-index: -1;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}
