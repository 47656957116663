#container-dashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

#title-container-dashboard {
    flex-grow: 0;
    display: flex;
    align-items: center;
    padding: 16px 30px;
}

#title-dashboard {
    font-size: 36px;
    font-weight: 900;
    display: inline-block;
}

#button-load-dashboard {
    margin-left: 8px;
}

#card-container-dashboard {
    padding: 0 30px;
    flex-grow: 1;
}

.ant-row {
}

.ant-col {
    height: 200px;
}

.ant-card {
    height: 100%;
    box-shadow: 0 8px 24px 0 #B0907033;
    border-radius: 24px;
    overflow: hidden;
}

.ant-card-body {
    padding: 0;
    height: 100%;
}
