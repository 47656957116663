#container-holdings-brief {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0
}

#titlebar-holdings-brief {
    padding: 0 24px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#title-holdings-brief {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    flex-grow: 1;
}

#main-section-holdings-brief {
    padding: 0 12px 0 24px;
}

#holdings-brief-earnings_today {
    font-family: "Bebas", sans-serif;
    /*color: #FFE600;*/
    font-size: 50px;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0;
}

#holdings-brief-earnings_today_percentage {
    font-family: "Bebas", sans-serif;
    /*color: #FFE600;*/
    font-size: 18px;
    font-weight: 100;
    line-height: 1em;
    margin-left: 8px;
}

#subtitlebar-holdings-brief {
    margin-top: 12px;
    padding: 0 12px 0 24px;
}

#subtitle-holdings-brief {
    font-size: 14px;
    line-height: 1em;
    margin-bottom: 0;
}

#minor-section-holdings-brief {
    margin-top: 4px;
    padding: 0 12px 0 24px;
}

#holdings-brief-total_amount {
    font-family: "Bebas", sans-serif;
    font-size: 36px;
    font-weight: 100;
    line-height: 1em;
    margin-bottom: 0;
}

#holdings-brief-dynamic_earnings_percentage {
    font-family: "Bebas", sans-serif;
    font-size: 14px;
    font-weight: 100;
    line-height: 1em;
    margin-left: 4px;
}
