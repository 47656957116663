#container-stock-pond-comparison {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0
}

#titlebar-stock-pond-comparison {
    padding: 0 24px;
    margin-top: 6px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: end;
}

#title-stock-pond-comparison {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0;
}

#title-tail-stock-pond-comparison {
    /*font-family: "Bebas", sans-serif;*/
    color: lightgray;
    font-size: 12px;
    /*font-weight: lighter;*/
    line-height: 1em;
    display: inline;
    margin-left: 6px;
}

#subtitlebar-stock-pond-comparison {
    padding: 0 24px;
    margin-bottom: 1px;
}

#subtitle-stock-pond-comparison {
    font-family: "Bebas", sans-serif;
    color: #FFE600;
    font-size: 40px;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0;
    flex-grow: 1;
}

#comparison-chart-container {
    flex-grow: 1;
    overflow: hidden;
}

.ant-spin-container {
    height: 100%;
}

#spin-comparison-chart {
    width: 100%;
    height: 100%;
}

#comparison-chart {
    width: 100%;
    height: 100%;
}
